<template>
<div>
    Remember to reset inactivity while playing
    <v-toolbar flat class="tabContainer">
        <v-tabs height="67" :hide-slider="true" v-model="ui.language" centered>
            <v-tab @click="selectLanguage(lang)" :href="'#'+lang" v-for="lang in languages" :key="'language_tab_'+lang">
                <span class="tab-text">{{lang=='en-ca' ? 'English Videos' : 'French Videos'}}</span>
            </v-tab>
        </v-tabs>
    </v-toolbar>

    <v-container>
        <v-col style="text-align: center;">
            <Library :tag="'on_demand'" :language="language" :config="config.library"/>
        </v-col>
    </v-container>
</div>
</template>

<script>
import Library from '@/components/Library/Library'
export default {
    created: function(){
        this.init()
    },
    components: {
        Library
    },
    data: function(){
        return {
            ui: {
                language: 'en'
            },
            config: {
                library: {
                    folderStructure: 'flat',
                    default_ta: 'scz',
                    fileTypes: ['vod','video','link','download','event'],
                    routes: ['OnDemand','OnDemandLanguage','OnDemandFolder','OnDemandItem'],
                    defaultFolder: 'videos'
                }
            }
        }
    },
    methods: {
        init: async function(){
            let language = this.language
            this.ui.language = language
            if(!language){
                this.$router.push({
                    name: "OnDemandLanguage",
                    params: {
                        language: this.$store.getters.language
                    }
                })
            }
        },
        selectLanguage: function(lang){
            this.ui.language = lang
            let folder = this.$route.params.folder
            let id = this.$route.params.id

            let name = this.config.library.routes[1]
            if(folder){
                name = this.config.library.routes[2]
            }
            if(id){
                name = this.config.library.routes[3]
            }

            this.$router.push({
                name,
                params: {
                    language: lang,
                    folder: folder,
                    id: id
                }
            })
        }
    },
    computed: {
        language: function(){
            return this.$route.params.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        appLanguage: function(){
            return this.$store.getters.language
        }
    },
    watch: {
        appLanguage: function(){
            this.selectLanguage(this.appLanguage)
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped lang="scss">
.tabContainer{
    background-color: $primary-light-1 !important;
    padding-top: 30px;
    height: auto !important;
}

.v-tab{
    padding: 20px 50px 20px 50px;
    width: 260px;
    margin: 2px;
    font-weight: bold;
    background-color: $primary-light-2;
    color: slategray;
    height: 55px;
    position: relative;
    top: 7px;
}


.v-tab--active{
    background-color: white;
    height: 80px;
    top: -10px;
}

.v-tab--active .tab-text{
    color: slategray;
}
</style>