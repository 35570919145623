<template>
<div style="text-align: left;" class="downloadCard">
    <div style="width: 100%; text-align: left;">
        <v-icon>
            mdi-cloud-download-outline
        </v-icon>
        {{item[language].label}}
    </div>

    <hr>
    
    <div v-html="item[language].body"/>
</div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        selection: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
.downloadCard img.logo{
    height: 50px;
}

.downloadCard h2{
    font-size: 12pt;
    margin: 15px;
}
</style>