<template>
  <div>
        <div v-if="debug" style="text-align:left;">
            <json-viewer :value="selection"/>
            <hr>
            <json-viewer :value="mockData"/>
        </div>

      <v-container v-if="ready">
          <v-row v-if="!selection.id">
            <v-col cols="3" style="position: relative;min-height: 800px;text-align: left;">
                <v-navigation-drawer v-model="ui.drawer" absolute height="100%" :width="config.folderStructure=='tree' ? 360 : 'auto'">
                    <v-list-item>
                        <v-list-item-avatar>
                        <v-img :src="images.resource_icon"/>
                        </v-list-item-avatar>

                        <v-list-item-content>
                        <v-list-item-title>Resources</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-switch v-if="debug" v-model="config.folderStructure" :true-value="'tree'" :false-value="'flat'" label="Tree Stucture"/>

                    <v-divider></v-divider>

                    <template v-if="config.folderStructure=='flat'">
                        <v-list>
                            <template v-for="(folder, folderKey) in resources.folders">
                                <v-list-item
                                    :class="[{'active':selection.folder==folderKey}]"
                                    :key="folderKey"
                                    @click="switchFolder(folderKey)"
                                    link
                                >
                                    <v-list-item-content>
                                        <v-list-item-title><Str :index="'resources.'+folderKey"/></v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>

                                </v-list-item>
                            </template>
                        </v-list>
                    </template>

                    <v-divider></v-divider>

                    <template v-if="config.folderStructure=='tree'">

                        <v-expansion-panels v-model="ui.panel">
                            <template v-for="(folder, folderKey) in resources.folders">
                                <v-expansion-panel v-if="Object.keys(folder).length>1" :key="selection.resource_area+'_'+folderKey">
                                    <v-expansion-panel-header @click="switchFolder(folderKey)" :class="[{'active':selection.folder==folderKey}]">
                                        <Str :index="'resources.'+folderKey"/>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <template v-for="(item, itemKey) in folder">
                                            <v-list v-if="item.length==undefined" dense :key="selection.resource_area+'_'+folderKey+'_'+itemKey">
                                                <v-list-item
                                                    :class="[{'active':selection.folder==folderKey+'.'+itemKey}]"
                                                    :key="itemKey"
                                                    @click="switchFolder(folderKey+'.'+itemKey)"
                                                    link
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <Str :index="'resources.nav.'+folderKey+'.'+itemKey"/>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-icon>
                                                        <v-icon>mdi-chevron-right</v-icon>
                                                    </v-list-item-icon>

                                                </v-list-item>
                                            </v-list>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>                         
                            </template>
                        </v-expansion-panels>


                        <v-list>
                            <template v-for="(folder, folderKey) in resources.folders">
                                <v-list-item
                                    v-if="Object.keys(folder).length==1"
                                    :key="selection.resource_area+'_'+folderKey"
                                     :class="[{'active':selection.folder==folderKey}]"
                                    @click="switchFolder(folderKey); ui.panel=undefined"
                                    link
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <Str :index="'resources.'+folderKey"/>
                                        </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>

                                </v-list-item>
                            </template>
                        </v-list>   

                    </template>

                </v-navigation-drawer>              
            </v-col>


            <v-col cols="9">

                <div class="resource-container" v-if="selection.folder">
                    <template v-if="config.folderStructure=='flat'">
                        <template v-for="(item, itemIndex) in returnRecursiveItems(resources.folders[selection.folder])">
                            <div v-if="item[language]" :key="'resource_card_'+itemIndex+'_'+item.id" :class="['resourceCard '+item.id, {'active':selection.resource_id==item.id}, {'inherent':item.tags.includes('lai')}]" @click="selectItem(item)">
                                <ResourceCard :locked="!allowItemAccess(item)" :item="item" :language="language" :selection="selection"/>
                            </div>
                        </template>
                    </template>

                    <template v-if="config.folderStructure=='tree'">
                        <template v-for="(item, itemIndex) in returnFolderItems(resources.folders, selection.folder)">
                            <div v-if="item[language]" :key="'resource_card_'+itemIndex+'_'+item.id" :class="['resourceCard '+item.id, {'active':selection.resource_id==item.id}, {'inherent':item.tags.includes('lai')}]" @click="selectItem(item)">
                                <ResourceCard :locked="!allowItemAccess(item)" :item="item" :language="language" :selection="selection"/>
                            </div>
                        </template>
                    </template>
                </div>

                <template v-else>
                    Please select a resource type and category
                </template>
                
            </v-col>              
        </v-row>
        
        <v-row v-else>
            <v-col cols="12">
                <vodFlow :selection="selection" :language="language" :item="itemMap[selection.id]"/>
            </v-col>
        </v-row>
      </v-container>

      


  </div>
</template>

<script>
import ResourceCard from './ResourceCard.vue'
import resource_icon from './assets/resources.png'
import vodCard from './cards/vod.vue'
import vodFlow from './flows/vodFlow.vue'


export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        filters: {
            type: Object,
            required: false
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        tag: {
            type: String,
            required: false,
            default: 'resources'
        }
    },
    components: {
        ResourceCard,
        vodCard,
        vodFlow
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            ready: false,
            form: {
                stats: null
            },
            ui: {
                taAccess: false,
                itemAccess: false,
                drawer: true,
                panel: []
            },
            accessRequirements: {
                modules: {
                    event: false,
                    consent: true,
                    eventSelection: null
                }
            },
            temp: {
                area: {
                    switchTo: null
                }
            }
        }
    },
    methods: {
        init: async function(){
            await this.getResources()
            this.ready = true
            console.log('selecting folder',{
                config: this.config,
                folder: this.config.startFolder
            })
            if(!this.selection.folder){
                this.$router.push({name: this.config.routes[2], params: {language: this.language, folder: this.config.defaultFolder}})
            }
        },
        getResources: async function(){
            let self = this
            function getData(){
                return new Promise((resolve, reject)=>{
                    setTimeout(function(){
                        resolve(self.mockData.resources)
                    },200)
                })
            }
            await getData()
        },
        // filter: function(item){
        //     let selection = this.selection
        //     let allowCategory = item.category == selection.resourceCategory
        //     let allowType = item.resourceType == selection.resourceType
        //     return allowCategory && allowType
        // },
        switchFolder: function(folder){
            let name = this.config.routes[2]
            let language = this.language
            this.$router.push({name, params: {language, folder}})
        },
        selectItem: function(item){
            // let name = this.$route.matched[1].name == 'Resources' ? 'resourceItem' : 'speakerResourceItem'
            let name = this.config.routes[3]
            let folder = this.selection.folder
            let accessRequirements = this.accessRequirements

            if(this.allowItemAccess(item)){
                this.ui.itemAccess = false
                this.$router.push({name, params: {folder, id: item.id}});
            }else{
                console.log('selectItem',{
                    item,
                    accessRequirements
                })
                this.ui.itemAccess = true
            }
        },
        allowItemAccess: function(item){
            return true
        },
        returnFolderAccess: function(folders){
            let output = {
                protected: [],
                open: []
            }
            for(let key in folders){
                let folder = folders[key]
                if(folder.protected){
                    output.protected.push(folder)
                }else{
                    output.open.push(folder)
                }
            }
            return output
        },
        returnItemTypes: function(items,language){
            let output = []
            for(let i=0; i<items.length; i++){
                let item = items[i][language]
                if(!output.includes(item.type)){
                    output.push(item.type)
                }
            }
            return output
        },
        returnFolderItems: function(folders, path){
            console.log({
                folders,
                path
            })
            let output = eval("folders."+path+".items")
            return output
        },
        returnRecursiveItems: function(folder){
            let container = []

            function recurse(objects){
                for(let key in objects){
                    let object = objects[key]
                    if(object.length!=undefined){
                        container = container.concat(object)
                    }else{
                        recurse(object)
                    }
                }
            }
            
            recurse(folder)
            return container
        },
        returnAreaItems: function(folders){
            let itemsCounter = 0
            for(let folderKey in folders){
                let folder = folders[folderKey]
                let items = this.returnRecursiveItems(folder).length
                itemsCounter+=items
            }
            return itemsCounter
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        selection: function(){
            let language = this.$route.params.language
            let folder = this.$route.params.folder
            let id = this.$route.params.id
            return {
                language,
                folder,
                id
            }
        },
        itemMap: function(){
            let output = {}
            if(this.resources?.items){
                for(let i=0; i<this.resources.items.length; i++){
                    let item = this.resources.items[i]
                    output[item.id] = item
                }
            }
            return output
        },
        // resourceItems: function(){
        //     let selection = this.selection
        //     if(select.resource_area && selection.folder && selection.resource_type){
        //         let access = this.resources.access

        //     }
        // },
        selectedVideo: function(){
            let selection = this.selection
            let language = this.language
            if(selection.resource_type=='video' && selection.resource_id){
                let items = this.resources && this.resources && this.resources.folders[selection.folder] ? this.resources.folders[selection.folder].items : []
                for(let i=0; i<items.length; i++){
                    let item = items[i][language]
                    if(item.id==selection.resource_id){
                        this.ui.dialog = true
                        return item
                    }
                }
            }else{
                this.ui.dialog = false
            }
            return null
        },
        images: function(){
            return {
                resource_icon,
                backgrounds: {}  
            }
        },
        resources: function(){
            if(this.ready){
                let resources = this.mockData.resources
                    resources.folders = {}
                    let items = resources.items
                    for(let i=0; i<items.length; i++){
                        let item = items[i]
                        let itemFolders = item.folders
                        for(let fa=0; fa<itemFolders.length; fa++){
                            let folders = itemFolders[fa].split('.')
                            let path = []


                            let testString = ""
                            let pathString = ""
                            for(let p=0; p<folders.length; p++){
                                let part = folders[p]
                                path.push(part)
                                testString+="?."+part
                                pathString+="."+part
                                let test = eval("resources.folders"+testString)
                                if(test===undefined){
                                    eval("resources.folders"+pathString+"={items:[]}")
                                }
                            }
                            
                           eval("resources.folders"+pathString+".items.push("+JSON.stringify(item)+")")

                        }
                    }
                return resources
            }
            return null
        },
        mockData: function(){
            let self = this
            let output = {
                resources: generateResources()
            }

            function generateResources(){
                let id = 0
                

                let output = {
                    items: generateItems(3, ['vod'], ['videos'], ['*'])
                }

                function generateItems(totalItems, types, folders, accessRequirements, tags=[]){

                    let templates = {
                        download: [
                            {
                                label: 'Psychologic Treatments',
                                body: `
                                    <img class="logo" src="https://www.neuroscienceconnections.com/images/resources/logo_canmat.svg">
                                    <h2>CANMAT 2016 Depression Guidelines - Psychological Treatments</h2>
                                    <p>Canadian Network for Mood and Anxiety Treatments (CANMAT) 2016 Clinical Guidelines for the Management of Adults with Major Depressive Disorder: Section 2. Psychological Treatments.</p>
                                    <a target="_blank" href="download.php?f=link&amp;l=https://www.ncbi.nlm.nih.gov/pubmed/27486150">Abstract</a>&nbsp;/&nbsp;<a target="_blank" href="download.php?f=link&amp;l=https://journals.sagepub.com/doi/full/10.1177/0706743716659418">Full Article</a>                            
                                `
                            },
                            {
                                label: '',
                                body: `
					                <h2><a target="_blank" class="pdf" href="https://epicanada.org/wp-content/uploads/2015/04/M118_TMAS_Final_Fillable.pdf">Tool for Monitoring Antipsychotic side effects (TMAS)</a></h2>
					                <p>Schizophrenia and use of antipsychotics are independently associated with increased motor and metabolic abnormalities, which can contribute to non-adherence to medication, and increased morbidity and mortality. The CCEIP has developed a one-page clinical assessment tool for monitoring these potential side effects along with a corresponding video.</p>            
                                `
                            }
                        ],
                        video: [
                            {
                                id: 1,
                                label: 'IM Injection Anatomy – Deltoid',
                                link: {url:'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_30MB.mp4'}
                            },
                            {
                                id: 2,
                                label: 'Education Series 3D Anatomy Dorsogluteal',
                                link: {url:'https://test-videos.co.uk/vids/jellyfish/mp4/h264/1080/Jellyfish_1080_10s_30MB.mp4'}
                            },
                            {
                                id: 3,
                                label: 'IM Injection Anatomy – Ventrogluteal',
                                link: {url:'https://test-videos.co.uk/vids/sintel/mp4/h264/1080/Sintel_1080_10s_30MB.mp4'}
                            }                            
                        ],
                        vod: [
                            {
                                id: 1,
                                label: 'IM Injection Anatomy – Deltoid',
                                link: {url:'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_30MB.mp4'},
                                postQuestions: 2
                            },
                            {
                                id: 2,
                                label: 'Education Series 3D Anatomy Dorsogluteal',
                                link: {url:'https://test-videos.co.uk/vids/jellyfish/mp4/h264/1080/Jellyfish_1080_10s_30MB.mp4'},
                                postQuestions: 2
                            },
                            {
                                id: 3,
                                label: 'IM Injection Anatomy – Ventrogluteal',
                                link: {url:'https://test-videos.co.uk/vids/sintel/mp4/h264/1080/Sintel_1080_10s_30MB.mp4'},
                                postQuestions: 2
                            }                            
                        ],
                        link: [
                            {
                                label: 'CADDRA Covid 19 Resources for Patients',
                                link: {url:'https://epicanada.org/'}
                            },
                            {
                                label: 'SwitchRx',
                                link: {url:'https://www.switchrx.com/'}
                            },
                            {
                                label: 'Canadian Consortium for Early Intervention in Psychosis --> Doctor and Healthcare Professional portal',
                                link: {url:'https://test-videos.co.uk/vids/sintel/mp4/h264/1080/Sintel_1080_10s_30MB.mp4'}
                            }
                        ]
                    }

                    let items = []

                    

                    for(let i=0; i<totalItems; i++){
                        id++
                        let type = types[getRandomInt(types.length)]
                        let template = templates[type] ? templates[type][getRandomInt(templates[type].length)] : null

                        items.push({
                            accessRequirements,
                            folders,
                            tags,
                            id,
                            "en-ca": [1,3].includes(id) ? {
                                label: ['video','link','vod'].includes(type) ? template.label : type=='download' ? template.label : "itemID:"+id,
                                body: type=='download' ? template.body : null,
                                type,
                                value: ['video','link','vod'].includes(type) ? template : null
                            }: null,
                            "fr-ca": [2].includes(id) ? {
                                label: "itemID:"+id,
                                type,
                                value: ['video','link','vod'].includes(type) ? template : null
                            }: null
                        })

                        let value = null
                        switch(type){
                            // case"video":
                            //     let videos = [
                            //         {
                            //             id: 1,
                            //             link: {url:'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_30MB.mp4'}
                            //         },
                            //         {
                            //             id: 2,
                            //             link: {url:'https://test-videos.co.uk/vids/jellyfish/mp4/h264/1080/Jellyfish_1080_10s_30MB.mp4'}
                            //         },
                            //         {
                            //             id: 3,
                            //             link: {url:'https://test-videos.co.uk/vids/sintel/mp4/h264/1080/Sintel_1080_10s_30MB.mp4'}
                            //         }
                            //     ]
                            //     value = videos[getRandomInt(videos.length)]
                            //     items[items.length-1].en.value = value
                            //     items[items.length-1].fr.value = value 

                            // break;

                            // case"link":
                            //     let links = [
                            //         {
                            //             id: 1,
                            //             link: {url:'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_30MB.mp4'}
                            //         },
                            //         {
                            //             id: 2,
                            //             link: {url:'https://test-videos.co.uk/vids/jellyfish/mp4/h264/1080/Jellyfish_1080_10s_30MB.mp4'}
                            //         },
                            //         {
                            //             id: 3,
                            //             link: {url:'https://test-videos.co.uk/vids/sintel/mp4/h264/1080/Sintel_1080_10s_30MB.mp4'}
                            //         }
                            //     ]
                            //     value = links[getRandomInt(links.length)]
                            //     items[items.length-1].en.value = value
                            //     items[items.length-1].fr.value = value 

                            // break;

                            case"download":
                                let files = [
                                    {
                                        id: 1,
                                        link: {url:'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_30MB.mp4'}
                                    },
                                    {
                                        id: 2,
                                        link: {url:'https://test-videos.co.uk/vids/jellyfish/mp4/h264/1080/Jellyfish_1080_10s_30MB.mp4'}
                                    },
                                    {
                                        id: 3,
                                        link: {url:'https://test-videos.co.uk/vids/sintel/mp4/h264/1080/Sintel_1080_10s_30MB.mp4'}
                                    }
                                ]
                                value = files[getRandomInt(files.length)]
                                items[items.length-1].en.value = value
                                items[items.length-1].fr.value = value 

                            break;

                            case"event":
                                let now = new Date()
                                let start = new Date()
                                start.setDate((now.getDate()+1))
                                let end = new Date()
                                end.setDate((start.getDate()+1))
                                end.setHours((start.getHours()+1))

                                let eventData = {
                                    beforeJoin: getRandomInt(2) == 0 ? [{name:'needsAssessment',id}] : false,
                                    evaluation: getRandomInt(2) == 0 ? {name:'evaluation',id} : false,
                                    language: getRandomInt(11) < 9 ? 'en-ca' : 'fr-ca',
                                    start,
                                    end
                                }
                                items[items.length-1].en.value = eventData
                                items[items.length-1].fr.value = eventData 
                            break;
                        }
                    }
                    return items
                }

                function getRandomInt(max) {
                    return Math.floor(Math.random() * max);
                }



                return output
            }

            return output
        }
    },
    watch: {
        selection: {
            deep: true,
            handler: function(){
                let selection = this.selection
                if(!selection.language || !selection.folder){
                    this.init()
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.active{
    background-color: $background-primary !important;
    color: white !important;
}

.active .v-icon{
    color: white !important;
}

.activeWhite{
    background-color: white !important;
    color: $background-primary !important;
}


.btn-resourceType{
    border: 1px solid grey;
    padding: 25px !important;
    margin: 15px;
    border-radius: 15px;
}

.filter-button{
    cursor: pointer;
    position: relative;
    display: flex;
    width: 200px;
    height: 80px;
    background-color: $primary-light-3;
    color: $primary;
    margin: 8px;
    border: 1px solid lightgrey;
    transition: all 0.2s;
    border-radius: 15px;
    padding: 10px 0px 0px 0px;
    flex-direction: column;
    font-size: 18pt;
}
.filter-button div{
    position: relative;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
}

.filter-button img{
    position: absolute; top: 5px; left: 20px;
    height: 40px;
    margin: 0 auto;
}

.filter-button:hover{
    border: 1px solid white;
}

    .filter-button.activeWhite{
        // background-color: $background-secondary !important;
        font-weight: bold;
        background-color: white !important;
        color: $background-primary !important;
    }

    .filter-button .filter-button-number{
        color: $text-primary-light !important;
    }


.resource-container{
    position: relative;
    display: flex;
    justify-content: start;
    gap: 15px;
    flex-wrap: wrap;
}
.resourceCard{
    position: relative;
    width: 600px;
    height: 450px;
    border: 1px solid grey;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s;
}
    .resourceCard:hover{
        border-radius: 18px;   
        border-color: $background-primary;
        background-color: $background-secondary;
        color: white;
    }
    .resourceCard:hover, .resourceCard.active{
        border-radius: 18px;   
        border-color: $background-primary;     
    }

.inherent{
    // border: 1px solid lightgrey;
}
</style>