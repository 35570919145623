<template>
<div>
    <div style="width: 100%; text-align: left;">
        <v-icon>
            mdi-video-box
        </v-icon>
        {{item[language].label}}
    </div>

    <hr>    
  
    <img :src="images.temp_1"/>

    <v-dialog width="800" persistent v-model="ui.dialog">
        <v-card>
            <VideoPlayer v-model="form.stats" v-if="selectedVideo" :options="config.videoOptions" :videoData="item[language].value"/>
            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="close">
                    Close
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</div>
</template>

<script>
import temp_1 from '../assets/video_1.png'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        selection: {
            type: Object,
            required: true
        }
    },
    components: {
        VideoPlayer
    },
    data: function(){
        return {
            ui: {
                dialog: false
            },
            config: {
                videoOptions: {
                    allowResume: true,
                    autoplay: false
                }
            },
            form: {
                stats: null
            }          
        }
    },
    methods: {
        close: function(){
            this.$router.push({name: this.$route.matched[this.$route.matched.length-2].name, params:{area: this.$route.params.area, folder: this.$route.params.folder}})
        }
    },
    computed: {
        images: function(){
            return {
                temp_1
            }
        },
        selectedVideo: function(){
            let item = this.item
            let selection = this.selection
            let language = this.language
            let selected = item[language].type=='video' && selection.resource_id==item.id
            this.ui.dialog = selected
            return selected
        },
    }
}
</script>

<style scoped lang="scss">
img{
    width: 100%;
}
</style>