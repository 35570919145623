<template>
<div>
    <div :class="[{'locked': locked}]">
        <div v-if="debug">{{item.id}} / {{language}}</div>
        <videoCard v-if="item[language].type=='video'" :selection="selection" :language="language" :item="item"/>
        <linkCard v-else-if="item[language].type=='link'" :selection="selection" :language="language" :item="item"/>
        <downloadCard v-else-if="item[language].type=='download'" :selection="selection" :language="language" :item="item"/>
        <vodCard v-else-if="item[language].type=='vod'" :selection="selection" :language="language" :item="item"/>
        <template v-else>{{item[language].type}}</template>
    </div>


    <div v-if="locked" class="unlock-message">
        <h3 align="center" style="margin-top: 30%;">Click here to access</h3>
    </div>    
</div>
</template>

<script>
import videoCard from './cards/video.vue'
import linkCard from './cards/link.vue'
import downloadCard from './cards/download.vue'
import vodCard from './cards/vod.vue'


export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        locked: {
            type: Boolean,
            required: false,
            default: true
        },
        item: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        selection: {
            type: Object,
            required: true
        }
    },
    components: {
        videoCard,
        linkCard,
        downloadCard,
        vodCard
    }
}
</script>

<style scoped lang="scss">
.locked{
    opacity: 0.4;
    color: grey !important;
}

.unlock-message{
    position: absolute; 
    left: 0px; 
    top: 0px; 
    font-size: 22pt; 
    font-weight: bold;
    color: $text-primary;
    width: 100%;
    height: 100%;
}
</style>