<template>
<div>
    <div v-if="debug">
        {{stats}}
    </div>
    <v-btn v-if="options && options.allowResume && stats.resumePoint>0" @click="resume">Resume {{stats.resumePoint}}</v-btn>
    <video :id='playerID' style='width:100%; margin: 0 auto;' disablePictureInPicture controls controlsList="nodownload" onloaded @play="tracking" @resume="tracking" @seeking="seeking" @pause="paused" @ended="ended">
        <source :src="source" type="video/mp4">
    Your browser does not support the video tag.
    </video>
</div>
</template>

<script>
export default {
    props: {
        playerID: {
            type: String,
            required: false,
            default: 'videoPlayer'
        },
        videoData: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: false
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mounted: function(){
        let self = this
        let videoPlayer = document.getElementById(this.playerID);
        videoPlayer.addEventListener('contextmenu', event => event.preventDefault());
        videoPlayer.onloadeddata = function(){
            self.state = 'ready'
            self.init()
        }
    },
    data: function(){
        return {
            timer: null,
            resumed: false,
            state: 'loading',
            stats: {
                duration: null,
                currentTime: null,
                resumePoint: 0,
                percentagePlayed: 0
            }
        }
    },
    methods: {
        init: function(){
            let stats = this.stats
            let player = this.player
            stats.duration = player.duration
            stats.currentTime = 0
            stats.resumePoint = 0
            stats.percentagePlayed = 0
            this.state='ready'
            if(this.options && this.options.autoplay===true){
                player.play()
                this.tracking()
            }
        },
        tracking: function(){            
            let self = this
            let player = this.player
            let stats = this.stats

            clearInterval(self.timer)

            
            stats.currentTime = player.currentTime
            let currentPercentage = Math.floor((player.currentTime / player.duration) * 100)
            if(currentPercentage>stats.percentagePlayed){stats.percentagePlayed = currentPercentage}
            if(stats.resumePoint<player.currentTime){stats.resumePoint = player.currentTime}


            this.$emit('input',stats)

            if(!self.player.paused){
                self.state = 'playing'
                self.timer = setInterval(function(){
                    self.tracking()
                },1000)
            }

        },
        ended: function(){
            let self = this
            self.tracking()
            self.state = 'ended'
        },
        paused: function(){
            let self = this
            self.tracking()
            self.state = 'paused'
        },
        seeking: function(){
            let self = this
            self.tracking()
            self.state = 'seeking'
        },
        resume: function(){
            let player = this.player
            let stats = this.stats
            player.currentTime=stats.resumePoint
            player.play()
        }
    },
    computed: {
        $height: function(){
            return (window.innerHeight-25)+'px';
        },
        source: function(){
            return this.videoData.link.url;
        },
        player: function(){
            return document.getElementById(this.playerID);
        },
        link: function(){
            return this.videoData.link.url
        }
    },
    watch: {
        state: function(){
            this.$emit('state_change',this.state)
        },
        videoData: {
            deep: true,
            handler: function(){
                this.state = 'loading'
                this.player.load()
            }
        }
    }
}
</script>

<style>

</style>