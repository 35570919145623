<template>
<div>
    <div style="width: 100%; text-align: left;">
        <v-icon>
            mdi-earth
        </v-icon>
        {{item[language].label}}
    </div>

    <hr>
    
    <v-btn link :href="item[language].value.link.url" target="_blank" style="margin: 50px;">Open</v-btn>
</div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        selection: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>