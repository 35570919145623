<template>
<div>
        <v-card>
            <v-card-text>
                <img :src="images.temp_1"/>
            </v-card-text>
        </v-card>
</div>
</template>

<script>
import temp_1 from '../assets/video_1.png'
import Hashids from 'hashids'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        selection: {
            type: Object,
            required: true
        }
    },
    created: function(){
        this.Hasher = new Hashids('Temporary Sald')
    },
    data: function(){
        return {
            ui: {},
            config: {},
            state: 'confirm_access',
            Hasher: null
        }
    },
    methods: {
        init: async function(){
            
        },
        encode: function(input){
            let output = null
            if(input && this.Hasher){
                output = this.Hasher.encode(input)
            }
            return output
        },
        decode: function(input){
            let output = null
            if(input && this.Hasher){
                output = this.Hasher.decode(input)
            }
            return output
        },
        getQuestionSet: async function(){
            let self = this
            function getData(){
                return new Promise((resolve, reject)=>{
                    setTimeout(function(){
                        console.log('getQuestionSet',self.config)
                        let key = self.config.questionSetID
                        let storage = window.localStorage;
                        let cache = storage.getItem('survey_editor_cache');
                        if(cache){
                            cache = JSON.parse(cache);
                            if(cache[key]){
                                self.questionSet = cache[key]
                                resolve(self.questionSet)
                            }else{
                                reject()
                            }
                        }
                        
                    },1000)
                })
            }

            getData().then((response)=>{
                console.log('getQuestionSet - ok', response)
            },(error)=>{
                console.log('getQuestionSet - error', error)
            })
        },
        getEvaluations: async function(){
            let self = this
            function getData(){
                return new Promise((resolve, reject)=>{
                    setTimeout(function(){
                        resolve()
                    },1000)
                })
            } 
            
            await getData()
        },
        videoStateHandler: function(data){
            if(data=='ended'){
                this.ui.stepState[1] = true
                this.ui.step++
                let self = this
                setTimeout(function(){
                    self.$router.push({
                        name: 'EvaluationOnDemand',
                        params: {
                            event_id: self.item_id
                        }
                    })
                    setTimeout(function(){
                        self.$store.commit('language',self.language)
                    },800)
                },1000)
            }
        },     
        close: function(){
            this.$router.push({name: this.$route.matched[this.$route.matched.length-2].name, params:{area: this.$route.params.area, folder: this.$route.params.folder}})
        }
    },
    computed: {
        images: function(){
            return {
                temp_1
            }
        },
        item_id: function(){
            return this.encode(this.item.id)
        },
        debug: function(){
            return this.$store.getters.debug
        }
    }
}
</script>

<style scoped lang="scss">
img{
    width: 100%;
}
</style>