<template>
<div>
        <v-card-actions>
            <v-btn text @click="close">
                <v-icon>mdi-chevron-left</v-icon>
                Back
            </v-btn>
        </v-card-actions>    

        <v-card>
            <v-card-text>
                <template>
                    <v-stepper v-model="ui.step" vertical flat>
                        
                        <v-stepper-step step="1" :complete="ui.stepState[1]">
                            Review Video
                        </v-stepper-step>

                            <v-stepper-content step="1">
                                <VideoPlayer v-model="form.stats" :debug="debug" :options="config.videoOptions" :videoData="item[language].value" @state_change="videoStateHandler"/>
                            </v-stepper-content>
                        
                        <v-stepper-step step="2" :complete="ui.stepState[2]">
                            Event Evaluation
                        </v-stepper-step>

                            <v-stepper-content step="2">
                               ...Starting Evaluation...
                            </v-stepper-content>

                    </v-stepper>                  
                </template>
            </v-card-text>
            

        </v-card>
</div>
</template>

<script>
import temp_1 from '../assets/video_1.png'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import Hashids from 'hashids'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        selection: {
            type: Object,
            required: true
        }
    },
    created: function(){
        this.Hasher = new Hashids('Temporary Sald')
        this.init()
    },
    components: {
        VideoPlayer
    },
    data: function(){
        return {
            ready: false,
            ui: {
                dialog: false,
                step: 1,
                stepState: {
                    1: false,
                    2: false,
                }
            },
            config: {
                videoOptions: {
                    allowResume: true,
                    autoplay: false
                }
            },
            state: 'confirm_access',
            questionSet: null,
            form: {
                survey: {},
                sampleRequest: null,
                stats: null
            },
            evaluations: [],
            Hasher: null
        }
    },
    methods: {
        init: async function(){
            this.ready = false
            this.ready = true
        },
        encode: function(input){
            let output = null
            if(input && this.Hasher){
                output = this.Hasher.encode(input)
            }
            return output
        },
        decode: function(input){
            let output = null
            if(input && this.Hasher){
                output = this.Hasher.decode(input)
            }
            return output
        },
        videoStateHandler: function(data){
            if(data=='ended'){
                this.ui.stepState[1] = true
                this.ui.step++
                let self = this
                console.log('videoStateHandler',{                        params: {
                            event_id: self.item_id,
                            question_set_id: self.encode(self.item[self.language].value.postQuestions)
                        }})
                setTimeout(function(){
                    self.$router.push({
                        name: 'EvaluationOnDemand',
                        params: {
                            event_id: self.item_id,
                            question_set_id: self.encode(self.item[self.language].value.postQuestions)
                        }
                    })
                    setTimeout(function(){
                        self.$store.commit('language',self.language)
                    },800)
                },1000)
            }
        },     
        close: function(){
            this.$router.push({name: this.$route.matched[this.$route.matched.length-2].name, params:{area: this.$route.params.area, folder: this.$route.params.folder}})
        }
    },
    computed: {
        images: function(){
            return {
                temp_1
            }
        },
        item_id: function(){
            return this.encode(this.item.id)
        },
        debug: function(){
            return this.$store.getters.debug
        }
    }
}
</script>

<style scoped lang="scss">
img{
    width: 100%;
}
</style>